<template>
  <div id="mode" class="tab-pane add-new-visitor-form-container">
    <v-app>
      <div class="col-sm-12 user-card" style="padding: 20px">
        <v-row>
          <v-col cols="12">
            <h5>{{ $ml.get("setting.control_modes") }}:</h5>
            <v-container fluid>
              <v-combobox
                v-model="model"
                :label="$ml.get('setting.enter_new_mode')"
                multiple
                small-chips
                solo
              >
                <template v-slot:selection="{ attrs, item }">
                  <v-chip
                    v-if="item === Object(item)"
                    v-bind="attrs"
                    :color="`${item.color} lighten-3`"
                    label
                    small
                  >
                    <span class="pr-2">{{ item.text }}</span>
                    <v-icon small @click="deleteItem(item)">x</v-icon>
                  </v-chip>
                </template>
              </v-combobox>
            </v-container>
          </v-col>
        </v-row>
      </div>
    </v-app>
  </div>
</template>

<script>
export default {
  props: ["value"],
  name: "Mode",
  data() {
    return {
      activator: null,
      attach: null,
      colors: ["green", "purple", "indigo", "cyan", "teal", "orange"],
      editing: null,
      index: -1,
      nonce: 1,
      menu: false,
      model: [],
      x: 0,
      search: null,
      y: 0,
      lock: false,
    };
  },
  methods: {
    load() {
      if (this.value["DEVICE_POSSIBLE_MODES"]) {
        let res = this.value["DEVICE_POSSIBLE_MODES"].split(",");
        if (res) {
          this.nonce = 1;
          res.forEach((v) => {
            v = {
              text: v.trim(),
              color: this.colors[this.nonce - 1],
            };
            this.model.push(v);
            this.nonce++;
          });
        }
      }
    },
    deleteItem(item) {
      // let self = this;
      // this.$vueConfirm.confirm(
      //   {
      //     auth: false,
      //     message: `Are you sure?`,
      //     button: {
      //       no: "No",
      //       yes: "Yes"
      //     }
      //   },
      //   function(confirm) {
      //     if (confirm == true) {
      //       //self.$vueConfirm.close();
      //     }
      //   }
      // );

      let _this = this;
      this.$dialog
        .confirm(
          `<h3>${this.$ml.get("message.Delete")}</h3>` +
            this.$ml.get("message.confirmDelete"),
          {
            loader: false,
            okText: this.$ml.get("button.delete"),
            cancelText: this.$ml.get("button.cancel"),
            backdropClose: true,
          }
        )
        .then(() => {
          _this.model.forEach((it, i) => {
            if (it == item) {
              _this.model.splice(i, 1);
              _this.store(_this.model);
            }
          });
        });
    },
    store(val) {
      let v = [];
      val.forEach((item) => {
        v.push(item.text.trim());
      });
      let values = {};
      values = { data: [{ key: "DEVICE_POSSIBLE_MODES", value: v.join(",") }] };

      let config = {
        header: {
          "Content-Type": "application/json",
        },
        timeout: 20000,
      };
      this.axios.post("/settings", values, config).then((response) => {
        this.$notify({
          group: "info",
          type: "success",
          text: this.$ml.get("message.saveSuccess"),
        });
      });
    },
  },
  watch: {
    value(val) {
      this.lock = true;
      this.load();
      this.lock = false;
    },
    model(val, prev) {
      if (val.length === prev.length) return;

      this.model = val.map((v) => {
        if (typeof v === "string") {
          v = {
            text: v,
            color: this.colors[this.nonce - 1],
          };
          //this.items.push(v);

          this.nonce++;
        }
        return v;
      });
      this.store(this.model);
    },
  },
};
</script>

<style scoped></style>
